import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [TranslateModule],

  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  @Input() icon: string;
  @Input() class: string;
  @Input() label: string;

  get iconPath(): string {
    const basePath = 'assets/images/icons/';
    switch (this.icon) {
      case 'calendar-label':
        return `${basePath}icon-calendar.svg`;
      case 'role-label':
        return `${basePath}icon-role.svg`;
      case 'risk-label':
        return `${basePath}icon-risk.svg`;
      case 'status-label':
        return `${basePath}icon-status.svg`;
      case 'designation-label':
        return `${basePath}icon-designation.svg`;
      case 'severity-level-label':
        return `${basePath}icon-shortcuts.svg`;
      case 'category-label':
        return `${basePath}icon-category.svg`;
      case 'user-label':
        return `${basePath}icon-user.svg`;
      case 'process-group-label':
          return `${basePath}icon-role.svg`;
      case 'control-risk-label':
          return `${basePath}icon-risk-sm2.svg`;
      case 'control-implementation-label':
           return `${basePath}icon-implimentation.svg`;
      case 'control-efficiency-label':
           return `${basePath}icon-eficiancy.svg`;
      case 'type-label':
           return `${basePath}icon-compliance-history.svg` 
      case 'risk-rating-label':
           return `${basePath}icon-riskrating.svg`; 


      case 'overview-tab':
        return `${basePath}icon-overview.svg`;
      case 'tasklist-tab':
        return `${basePath}icon-tasklist.svg`;
      case 'notification-tab':
        return `${basePath}icon-notifications-sm.svg`;
      case 'settings-tab':
        return `${basePath}icon-settings-sm.svg`;
      case 'pdf-view-tab':
        return `${basePath}icon-pdf-preview.svg`;
      case 'associated-items-tab':
        return `${basePath}icon-associated-item.svg`;
      case 'action-plans-tab':
        return `${basePath}icon-controls.svg`;
      case 'rca-tab':
        return `${basePath}icon-rootcause.svg`;
      case 'risk-analysis-tab':
        return `${basePath}icon-risk-evaluation.svg`;
      case 'controls-tab':
        return `${basePath}icon-controls.svg`;
      case 'risk-response-tab':
        return `${basePath}icon-risk-response.svg`;
      case 'kri-tab':
        return `${basePath}icon-risk-indication.svg`;
      case 'residual-ca-tab':
        return `${basePath}icon-residual-control-anlysis.svg`;
      case 'residual-ra-tab':
        return `${basePath}icon-residual-risk.svg`;
      case 'pdf-tab':
        return `${basePath}icon-pdf-preview.svg`;
      case 'requirements-tab':
        return `${basePath}icon-compliance-sm2.svg`;
      case 'control-test-tab':
          return `${basePath}icon-control-new.svg`;

      case 'template-action':
        return `${basePath}icon-template.svg`;
      case 'import-action':
        return `${basePath}icon-import.svg`;
      case 'upload-action':
        return `${basePath}icon-upload.svg`;
      case 'export-action':
        return `${basePath}icon-upload.svg`;
      case 'archive-action':
        return `${basePath}icon-archive.svg`;
      case 'delete-action':
        return `${basePath}icon-delete.svg`;
      case 'refresh-action':
        return `${basePath}icon-fresh.svg`;
     

      case 'customize-button':
        return `${basePath}icon-costomize.svg`;
      case 'add-button':
        return `${basePath}icon-plus.svg`;
      case 'edit-button':
        return `${basePath}icon-pencil-line.svg`;
      case 'delete-button':
        return `${basePath}icon-delete.svg`;

      case 'pdf-icon-details':
        return `${basePath}icon-pdf-list.svg`;
      case 'word-icon-details':
        return `${basePath}icon-word-round.svg`;


      case 'building-icon-profile':
        return `${basePath}icon-building.svg`;
      case 'location-icon-profile':
        return `${basePath}icon-map.svg`;
      case 'industry-icon-profile':
        return `${basePath}icon-industry.svg`;
      case 'email-icon-profile':
        return `${basePath}icon-mail.svg`;
      case 'phone-icon-profile':
        return `${basePath}icon-phone.svg`;

      default:
        return `${basePath}icon-default.svg`;
    }
  }
}
